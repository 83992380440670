<template>
  <VContainer
    class="fill-height"
    style="position: relative;"
  >
    <VRow align="center">
      <VCol
        md="11"
        offset-md="1"
      >
        <h1 class="display-8 font-weight-bold">
          403
        </h1>

        <h2 class="text-h2 font-weight-bold">
          {{ $t('Error403.description') }}
        </h2>
      </VCol>
    </VRow>
  </VContainer>
</template>

<script>
export default {
  name: 'Error403',
};
</script>
